<template>
  <h1 v-if="$props.type === 'h1'" :class="$props.classes">{{ $props.text }}</h1>
  <h2 v-if="$props.type === 'h2'" :class="$props.classes">{{ $props.text }}</h2>
  <h3 v-if="$props.type === 'h3'" :class="$props.classes">{{ $props.text }}</h3>
</template>

<script>
export default {
  name: "Headline",
  props: ["type", "classes", "text"]
}
</script>